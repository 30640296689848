import React, { Component } from 'react'
import cn from 'classnames'
import { Animate } from 'react-animate-mount'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { space } from 'styled-system'

const Div = styled.div`
  ${space}
  color: ${props => props.theme.color.danger};
  font-family: ${props => props.theme.fonts.primary};
  font-size: 16px;
  line-height: 24px;
  min-height: 24px;
`

export class InputError extends Component {
  state = {
    error: undefined,
    isOpen: false
  }
  static getDerivedStateFromProps(props, state) {
    const { children } = props
    const newError = children || state.error
    return { error: newError, isOpen: !!children }
  }

  render() {
    const { className, children, ...rest } = this.props
    const { error, isOpen } = this.state

    return (
      <Animate appear show={isOpen}>
        <Div className={cn(className, 'error')} {...rest}>
          {error}
        </Div>
      </Animate>
    )
  }
}
InputError.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default InputError
