import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'styled-system'

// BaseButton also used by ButtonGroup
export const BaseButton = styled.button`
  border-radius: ${props => props.theme.borderRadius};
  border-color ${props => props.theme.transition}, color ${props =>
  props.theme.transition};
  cursor: pointer;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 14px;
  min-width: 68px;
  overflow: visible;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color ${props => props.theme.transition},
  user-select: none;
  ${space}
`

const Component = styled(BaseButton)`
  background-color: ${props => props.theme.color.brandPrimary};
  border: solid 2px ${props => props.theme.color.brandPrimary};
  color: ${props => props.theme.color.white};
  font-weight: bold

  &:hover {
    background-color: ${props => props.theme.color.brandPrimaryShade2};
    border-color: ${props => props.theme.color.brandPrimaryShade2};
  }

  &:focus {
    box-shadow: ${props => props.theme.color.boxShadow};
    outline: none;
  }

  &:active {
    background-color: ${props => props.theme.color.brandPrimary};
    border-color: ${props => props.theme.color.greyShade5};
  }

  &.secondary {
    color: ${props => props.theme.color.brandPrimaryTint1};
    background-color: transparent;
    border-color: ${props => props.theme.color.greyShade2};

    &:active {
      color: ${props => props.theme.color.brandPrimary};
      background-color: ${props => props.theme.color.brandPrimaryTint8};
      border-color: ${props => props.theme.color.brandPrimaryTint5};
    }

    &:hover {
      color: ${props => props.theme.color.brandPrimaryShade2};
      border-color: ${props => props.theme.color.greysShade3};
    }
  }

  &.destructive {
    color: ${props => props.theme.color.dangerLight};
    background-color: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.greyShade2};

    &:active,
    &:hover {
      background-color: ${props => props.theme.color.white};
      border-color: ${props => props.theme.color.greyShade4};
    }
  }

  &[disabled],
  &.disabled {
    background-color: ${props => props.theme.color.greyShade3};
    border-color: ${props => props.theme.color.greyShade3};
    color: ${props => props.theme.color.greyShade2};
    cursor: not-allowed;
    user-select: none;
  }

  &.secondary[disabled],
  &.secondary.disabled {
    background-color: transparent;
    border-color: ${props => props.theme.color.greyShade1};
    color: ${props => props.theme.color.greyShade2};
    cursor: not-allowed;
    user-select: none;
  }
`

export const Button = ({ children, space, ...rest }) => (
  <Component {...rest}>{children}</Component>
)
Button.propTypes = {
  children: PropTypes.node,
  space: PropTypes.number
}
export default Button
