import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button } from '../js/components/Button';
import { InputError } from '../js/components/InputError';
import { Props, Playground } from 'docz';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "inputerror"
    }}>{`InputError`}</h1>
    <p><inlineCode parentName="p">{`import { InputError } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`InputError makes it easy to show errors in a standard way that includes animation. Simply provide children or no children. `}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={InputError} mdxType="Props" />
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'<ThemeProvider theme={themeConWeb}>\n  <InputError>Oops something went wrong!</InputError>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Button,
      InputError,
      Props,
      Playground,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
  <InputError mdxType="InputError">Oops something went wrong!</InputError>
  </ThemeProvider>
    </Playground>
    <h2 {...{
      "id": "code-example"
    }}>{`Code Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-JS"
      }}>{`export default class Demo extends Component {
  state = {
    error: undefined
  }
  toggleErr = e => {
    const { error } = this.state
    this.setState({
      error: error ? undefined : 'Woops! Something went wrong. '
    })
  }
  render() {
    const { error } = this.state
    return (
      <>
        <Button onClick={this.toggleErr}>
          {error ? \`Hide Error\` : \`Throw Error\`}
        </Button>
        <InputError>{error}</InputError>
      </>
    )
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      